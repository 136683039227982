import {
  AddUserToOfficeRequest,
  AssignNurseToItineraryRequest,
  AssignNurseToShiftRequest,
  AssignNurseToShiftResponse,
  ByID,
  ByName,
  CopyDraftWaypointRequest,
  CopyDraftWaypointResponse,
  CreateFaxesFromFilesRequest,
  CreateFaxesFromFilesResponse,
  CreateOfficeRequest,
  CreateOfficeResponse,
  CreateOrderRequest,
  CreateShopifyOrderRequest,
  CreateShopifyOrderResponse,
  CreateUserRequest,
  CreateUserResponse,
  CreateUserWithEmailLinkRequest,
  CreateUserWithEmailLinkResponse,
  GenerateDenormalizedOrdersRequest,
  GenerateDenormalizedOrdersResponse,
  GenerateProcedureResultDocumentManuallyRequest,
  GenerateReqFormRequest,
  GetAlgoliaAPIKeyResponse,
  GetHealthGorillaInsuranceProvidersResponse,
  GetItineraryBaseNursePayRequest,
  GetItineraryBaseNursePayResponse,
  GetItineraryDataRequest,
  GetItineraryDataResponse,
  GetItineraryRoutesRequest,
  GetItineraryRoutesResponse,
  GetRequiredTemplateVariablesByProductIDRequest,
  GetRequiredTemplateVariablesByProductIDResponse,
  NotifySchedulingUserOnOrderRequest,
  RegisterNursesRequest,
  RegisterNursesResponse,
  SendInvitationsToOfficeRequest,
  SendInvitationsToOfficeResponse,
  SetAssistantsDefaultSubscriptionsByOfficeIDsRequest,
  SetAssistantsDefaultSubscriptionsByOfficeIDsResponse,
  SetProcedureStatusesRequest,
  SetProcedureStatusesResponse,
  UpdateAlgoliaIndexesRequest,
  UpdateAlgoliaIndexesResponse,
  UpdateNurseStripeAccountsRequest,
  UpdateNurseStripeAccountsResponse,
  UpdateOrderRequest,
  UpdateOrderResponse,
  UpdateUsersAndOfficesWalletRequest,
  UpdateUsersAndOfficesWalletResponse,
  WithWarmUp,
} from '@caresend/types';
import { firebaseCallable } from '@caresend/ui-components';

import { batchedCallable } from '@/database/firebase/API/batching';

export const addUserToOfficeRequest = firebaseCallable<
  AddUserToOfficeRequest,
  void
>('addUserToOffice');

export const assignNurseToItineraryRequest = firebaseCallable<
  AssignNurseToItineraryRequest,
  void
>('assignNurseToItinerary');

export const assignNurseToShiftRequest = firebaseCallable<
  AssignNurseToShiftRequest,
  AssignNurseToShiftResponse
>('assignNurseToShift');

export const copyDraftWaypointRequest = firebaseCallable<
  CopyDraftWaypointRequest,
  CopyDraftWaypointResponse
>('copyDraftWaypoint');

export const createFaxesFromFilesRequest = firebaseCallable<
  CreateFaxesFromFilesRequest,
  CreateFaxesFromFilesResponse
>('createFaxesFromFiles');

export const createOrderRequest = firebaseCallable<
  WithWarmUp<CreateOrderRequest>,
  void
>('createOrder');

export const createShopifyOrder = firebaseCallable<
  CreateShopifyOrderRequest,
  CreateShopifyOrderResponse
>('createShopifyOrder');

export const createUserWithEmailLinkRequest = firebaseCallable<
  CreateUserWithEmailLinkRequest,
  CreateUserWithEmailLinkResponse
>('createUserWithEmailLink');

export const createUserRequest = firebaseCallable<
  CreateUserRequest,
  CreateUserResponse
>('createUser');

export const generateProcedureResultDocumentManuallyRequest = firebaseCallable<
  GenerateProcedureResultDocumentManuallyRequest,
  void
>('generateProcedureResultDocumentManually');

export const generateReqFormRequest = firebaseCallable<
  GenerateReqFormRequest,
  void
>('generateReqForm');

export const getInsuranceProvidersFromHealthGorillaRequest = firebaseCallable<
  void,
  GetHealthGorillaInsuranceProvidersResponse
>('getInsuranceProvidersFromHealthGorilla');

export const generateDenormalizedOrdersRequest = firebaseCallable<
  GenerateDenormalizedOrdersRequest,
  GenerateDenormalizedOrdersResponse
>('generateDenormalizedOrders');

export const setAssistantsDefaultSubscriptionsByOfficeIDs = firebaseCallable<
  SetAssistantsDefaultSubscriptionsByOfficeIDsRequest,
  SetAssistantsDefaultSubscriptionsByOfficeIDsResponse
>('setAssistantsDefaultSubscriptionsByOfficeIDs');

export const getItineraryBaseNursePayRequest = firebaseCallable<
  GetItineraryBaseNursePayRequest,
  GetItineraryBaseNursePayResponse
>('getItineraryBaseNursePay');

export const getItineraryDataRequest = batchedCallable<
  GetItineraryDataRequest,
  GetItineraryDataResponse
>('getItineraryData');

export const getItineraryRoutesRequest = firebaseCallable<
  GetItineraryRoutesRequest,
  GetItineraryRoutesResponse
>('getItineraryRoutes');

export const getUsersMatchingAllCityFiltersRequest = firebaseCallable<
  void,
  ByName<ByID<boolean>>
>('getUsersMatchingAllCityFilters');

export const getRequiredTemplateVariablesByProductIDRequest = firebaseCallable<
  GetRequiredTemplateVariablesByProductIDRequest,
  GetRequiredTemplateVariablesByProductIDResponse
>('getRequiredTemplateVariablesByProductID');

export const getSuperAdminAlgoliaAPIKey = firebaseCallable<
  void,
  GetAlgoliaAPIKeyResponse
>('getSuperAdminAlgoliaAPIKey');

export const notifySchedulingUserOnOrderRequest = firebaseCallable<
  NotifySchedulingUserOnOrderRequest,
  void
>('notifySchedulingUserOnOrder');

export const registerNursesRequest = firebaseCallable<
  RegisterNursesRequest,
  RegisterNursesResponse
>('registerNurses');

export const sendInvitationsToOfficeRequest = firebaseCallable<
  SendInvitationsToOfficeRequest,
  SendInvitationsToOfficeResponse
>('sendInvitationsToOffice');

export const setProcedureStatusesRequest = firebaseCallable<
  SetProcedureStatusesRequest,
  SetProcedureStatusesResponse
>('setProcedureStatuses');

export const unassignNurseFromShiftRequest = firebaseCallable<
  AssignNurseToShiftRequest,
  void
>('unassignNurseFromShift');

export const updateNurseStripeAccountsRequest = firebaseCallable<
  UpdateNurseStripeAccountsRequest,
  UpdateNurseStripeAccountsResponse
>('updateNurseStripeAccounts');

export const unassignNurseToItineraryRequest = firebaseCallable<
  AssignNurseToItineraryRequest,
  void
>('unassignNurseToItinerary');

export const updateOrderRequest = firebaseCallable<
  UpdateOrderRequest,
  UpdateOrderResponse
>('updateOrder');

export const updateAlgoliaIndexesRequest = firebaseCallable<
  UpdateAlgoliaIndexesRequest,
  UpdateAlgoliaIndexesResponse
>('updateAlgoliaIndexes');

export const updateUsersAndOfficesWalletRequest = firebaseCallable<
  UpdateUsersAndOfficesWalletRequest,
  UpdateUsersAndOfficesWalletResponse
>('updateUsersAndOfficesWallet', { timeout: 540000 });

export const createOfficeRequest = firebaseCallable<
  CreateOfficeRequest,
  CreateOfficeResponse
>('createOffice');
